.home-background {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Mobilde yüksekliği sınırlamak için vh kullanabilirsiniz */
  position: relative;
  z-index: 1;
  overflow: hidden; /* Kaydırmayı engeller, sadece bu öğe için geçerli */
}

.home-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(7, 7, 7, 0.6) 0.22%,
    rgba(3, 3, 3, 0.343874) 28.25%,
    rgba(0, 0, 0, 0.1) 100%
  );
  backdrop-filter: blur(10px);
  z-index: -1;
}

/* Banner altındaki metni mobilde gizleyelim */
.banner-info {
  font-size: 0.95rem;
  margin: 1rem;
  font-weight: 600;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Gölge ekleniyor */
}

/* Mobil görünümde kaydırmayı engelle */
@media (max-width: 768px) {
  .home-background {
    height: 100vh; /* Yüksekliği sınırlı tutuyoruz */
    overflow: hidden; /* Kaydırmayı sadece bu öğede engelliyoruz */
  }

  .banner-info {
    font-size: 0.5rem;
    margin: 0.5rem;
  }
}
