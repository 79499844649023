.image-gallery {
  position: relative;
  width: 50%; /* Webde daha küçük genişlik */
  margin: auto; /* Ortalamak için */
  display: grid; /* Grid layout */
  grid-template-columns: repeat(2, 1fr); /* 2 sütun */
  gap: 15px; /* Görseller arası boşluk */
  height: auto; /* Yükseklik dinamik */
}

.image-container {
  position: relative;
  border-radius: 10px; /* Yuvarlak köşeler */
  overflow: hidden;
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(199, 199, 199, 0.8) 0%,
    rgba(199, 199, 199, 0.1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animasyon ekledik */
}

.image-container:hover {
  transform: scale(1.05); /* Hover üzerinde büyüme efekti */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Gölge efekti */
}

.gallery-image {
  width: 100%; /* Tam genişlik */
  height: auto;
  border-radius: 35px;
  object-fit: cover; /* Görsellerin düzgün oranı */
  transition: transform 0.3s ease; /* Resim üzerinde animasyon */
  transform-origin: bottom;
  transform: scale(1.01);
}

/* Mobil ve tablet için düzenleme */
@media (max-width: 768px) {
  .image-container {
    padding: 10px;
    border-radius: 20px;
  }
  .image-gallery {
    grid-template-columns: 1fr; /* Mobilde 1 sütun */
    gap: 10px; /* Görseller arası boşluk biraz küçültülmüş */
  }

  .gallery-image {
    width: 100%; /* Görsellerin tam genişliği */
    height: auto; /* Görsellerin yüksekliğini oranla ayarla */
    border-radius: 15px;
  }
}

@media (max-width: 1024px) {
  .image-gallery {
    width: 90%; /* Tablet için genişlik ayarlandı */
    grid-template-columns: repeat(2, 1fr); /* Tablet için 2 sütun */
    gap: 12px; /* Tablet görseller arası boşluk */
  }

  .gallery-image {
    width: 100%; /* Görsellerin tam genişliği */
    height: auto; /* Görsellerin yüksekliğini oranla ayarla */
    border-radius: 20px;
  }
}
