.nav {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(7, 7, 7, 0.4) 0%,
    /* Opaklık azaltıldı */ rgba(0, 0, 0, 0.2) 100%
      /* Daha parlak bir ton eklendi */
  );
  backdrop-filter: blur(10px);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 700;
  line-height: 30px;
  font-size: 16px;
  z-index: 10;
  position: fixed;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 50px;
  width: auto;
}

.main_list.desktop {
  display: flex; /* Web görünümünde menüyü göster */
  gap: 1rem;
}

.main_list.mobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  background-color: rgba(27, 27, 27, 0.97);
  color: white;
  display: none; /* Başlangıçta gizli */
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-top: 5rem;
}

.main_list.mobile.show_list {
  display: flex;
  transform: translateX(0);
}

.navlinks {
  list-style: none;
  display: flex;
}

.navlinks li a:hover {
  color: #f68b1f;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.contact-button .contact-word:hover {
  background-color: #fff5f6;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.social-media .instagram-icon:hover {
  color: #f5a623;

  transform: scale(1.5);
  transition: all 0.3s ease-in-out;
}

.navlinks li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  align-items: center;
  display: flex;
  padding: 0.2rem 1rem;
}

.contact-button {
  display: flex;
  align-items: center;
}

.contact-word {
  background-color: #f68b1f;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.contact-icon {
  font-size: 20px;
}

/* Hamburger buton */
.navTrigger {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}

.navTrigger i {
  width: 25px;
  height: 3px;
  background-color: white;
}

/* Menü Açıkken Arka Plan Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Yarı saydam arka plan */
  z-index: 9; /* Mobil menüden daha düşük */
}

.social-media {
  display: flex;
  flex-direction: column;
}

.social-media .instagram-icon {
  font-size: 2rem; /* İkon boyutu */
  color: white;
  cursor: pointer;
}

/* Mobil Menü (Z-index güncellemesi) */
.main_list.mobile {
  z-index: 10; /* Overlay'den daha üstte */
}

/* Mobil Menü Kapatma Butonu (NavClose) */
.navClose {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* Responsive ayarlar */
@media screen and (max-width: 729px) {
  .main_list.desktop {
    display: none;
  }

  .main_list.mobile {
    display: none;
  }

  .navTrigger {
    display: flex;
  }

  .navlinks {
    flex-direction: column;
    gap: 3rem;
  }
  .contact-button {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 730px) {
  /* Mobil menüyü geniş ekranda gizle */
  .main_list.mobile {
    display: none;
  }

  /* Web menüsünü geniş ekranda göster */
  .main_list.desktop {
    display: flex;
  }

  /* Hamburger menüyü geniş ekranda gizle */
  .navTrigger {
    display: none;
  }
}
