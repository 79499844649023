/* Temel stiller */
.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 1rem;
}

.slider-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
}

.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider__track {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  list-style-type: none;
  margin: 0;
  scrollbar-width: none; /* Firefox */
  gap: 1rem;
}

.slider__track::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.slide {
  flex: 0 0 284px;
  width: 284px;
  height: 300px;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column; /* Dikey düzen */
  align-items: flex-start; /* İçerikler sola hizalanır */
  justify-content: space-between;
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(7, 7, 7, 0.8) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border-radius: 20px;
  padding: 1rem;
  backdrop-filter: blur(10px);
  text-align: left;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
}

.slide:hover {
  transform: scale(1.01); /* Yumuşak büyüme efekti */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4); /* Daha belirgin gölge */
}

.product-image {
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.3s ease;
}
.product-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0.25rem 0;
  text-align: left; /* Tüm başlıklar solda hizalanır */
}

.product-subtitle {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.25rem;
  text-align: left; /* Tüm alt başlıklar da solda hizalanır */
}

/* subtitle içeren kartlar için özel düzenleme */
.slide.with-subtitle .product-title {
  font-size: 1.4rem;
  margin-bottom: 0.5rem; /* Subtitle ile aralığı arttır */
  font-weight: 600;
}

/* subtitle içeren kartlar için alt başlık düzenlemesi */
.slide.with-subtitle .product-subtitle {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.25rem;
}

/* Mobil ekran */
@media (max-width: 768px) {
  .slider__track {
    gap: 0.4rem;
  }
  .slide {
    flex: 0 0 38%;
    height: 200px;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .product-image {
    height: 120px;
    border-radius: 20px;
    margin-bottom: 0rem;
  }

  .product-title {
    font-size: 0.8rem;
  }

  .product-subtitle {
    font-size: 0.8rem;
  }

  .slide.with-subtitle .product-title {
    font-size: 0.8rem;
    margin-bottom: 0rem; /* Subtitle ile aralığı arttır */
    font-weight: 600;
  }
  .slide.with-subtitle .product-subtitle {
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 0rem;
  }
}

/* Tablet ekran */
@media (min-width: 769px) and (max-width: 1024px) {
  .slide {
    flex: 0 0 30%;
    height: 300px;
    margin: 0.75rem;
    padding: 0.75rem;
  }

  .product-image {
    height: 210px;
  }

  .product-title {
    font-size: 1.1rem;
  }

  .product-subtitle {
    font-size: 0.9rem;
  }
}

/* Büyük ekran */
@media (min-width: 1025px) {
  .slide {
    flex: 0 0 284px;
    height: 300px;
  }

  .product-image {
    height: 220px;
  }

  .product-title {
    font-size: 1.5rem;
  }

  .product-subtitle {
    font-size: 1rem;
  }
}
