.footer {
  background-color: #1a1a1a;
  color: #ccc;
  padding: 1rem 0;
  width: 100%;
  height: auto;

  /* background: linear-gradient(
    90deg,
    #000000 0%,
    rgba(112, 112, 112, 0.1) 41.77%
  ); */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 20px;
}

.footer-left {
  text-align: left;
  flex: 1;
}

.footer-logo {
  width: 170px;
  margin-bottom: 10px;
}

.footer-left p {
  color: #d3d3d3;
  font-size: 0.75rem;
}

.footer-center {
  text-align: center;
  flex: 4;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.footer-links li {
  margin: 0;
}

.footer-links a {
  color: #d3d3d3;
  font-size: 0.85rem;
  text-decoration: none;
  font-weight: 700;
}

.footer-links a:hover {
  color: #f68b1f;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.footer-right {
  text-align: left;
  flex: 1;
}

.footer-right p {
  margin-bottom: 5px;
  color: #d3d3d3;
  font-size: 0.85rem;
}

.social-icon {
  width: 24px;
  height: 24px;
}

.footer-bottom {
  text-align: center;
  margin-top: 10px;
  font-size: 0.85rem;
  color: #777;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Bileşenleri dikey olarak hizala */
    text-align: center;
    font-size: 0.875rem;
  }

  .footer-left {
    order: 3; /* Logoyu en alt sıraya taşı */
    margin-top: 20px; /* Üst kısımdan boşluk ekle */
  }

  .footer-center {
    order: 1; /* Ortadaki içerik üstte kalacak */
    flex-direction: column; /* Dikey hizalama */
    gap: 20px; /* Footer links arasına boşluk ekle */
  }

  .footer-links {
    gap: 10px; /* Her grup arasında boşluk */
    text-align: center;
  }

  .footer-right {
    order: 2; /* Sağdaki içerik orta sırada */
    margin-top: 20px;
    text-align: center; /* Mobilde içeriği ortala */
  }

  .social-icon {
    display: block;
    margin: 0 auto; /* Instagram ikonunu ortala */
    width: 32px; /* Mobilde biraz daha büyük görünmesi için boyutu artır */
    height: 32px;
  }
}
