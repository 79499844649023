.banner-about {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.banner-image-about {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Renkli bulanık efekt için ::before ile ekleme */
.banner-about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(7, 7, 7, 0.6) 0.22%,
    rgba(3, 3, 3, 0.343874) 28.25%,
    rgba(0, 0, 0, 0.1) 100%
  );
  z-index: 1;
  pointer-events: none;
}

.banner-text-about {
  position: absolute;
  top: 75%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 74px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: Montserrat;
  z-index: 2; /* Metnin önde görünmesi için */
}

@media (max-width: 768px) {
  .banner-text-about {
    font-size: 1.5rem; /* Mobil cihazlarda daha küçük boyut */
    line-height: 50px; /* Yazı aralığını da mobil uyumlu yap */
    font-weight: 600;
  }
  .banner-about {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .banner-text-about {
    font-size: 1.25rem; /* Daha küçük ekranlar için daha küçük boyut */
    line-height: 40px;
    font-weight: 600;
  }
}
