/* Genel container ve içerik stil */
.HtbkInfo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  box-sizing: border-box; /* Padding'i dahil eder */
  margin-bottom: 2rem;
}

/* İçeriğin genişliği ve hizalaması */

.HtbkInfo-content {
  width: 80%; /* Ekranın %90 genişliğinde */
  display: flex;
  justify-content: space-between; /* İçeriği yatayda ortalar */
}

/* Sol kısımdaki metin bölümü */
.HtbkInfo-text {
  flex: 1;
  color: #333;
  max-width: 520px;
  text-align: left; /* Sol hizalı metin */
}

/* Başlık ve paragraf stilleri */
.HtbkInfo-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  display: inline-block;
  padding-bottom: 5px;
}

.HtbkInfo-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  /* İçeriğin genişliği ve hizalaması */
  .HtbkInfo-container {
    padding: 0px;
  }
  .HtbkInfo-title {
    font-size: 1.5rem;
  }
}
