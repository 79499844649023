/* Genel container ve başlık */
.gallery-container {
  width: 100%;

  display: flex;
  align-items: center; /* Yazı ve görseller aynı hizaya gelir */
  flex-direction: column;
  margin-bottom: 2rem;
}

/* Yazı alanı */
.gallery-content {
  width: 80%;
  flex-basis: 20%; /* Yazı alanının genişliği */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Yazıları sola hizala */
}

.gallery-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;

  padding-bottom: 5px;
  text-align: left; /* Yazıyı sola hizala */
}

/* Görsel galerisi alanı */
.gallery-images-container {
  flex: 1; /* Görseller alanını genişlet */
  display: flex;
  justify-content: center; /* Görselleri ortala */
  align-items: center;
}

.gallery-images {
  display: flex;
  justify-content: space-between; /* Görseller arası boşluk */
  align-items: center;
  flex-wrap: wrap; /* Görselleri birden fazla satıra yerleştir */
  gap: 3rem;
  width: 100%;
}

/* Her bir görsel */
.gallery-images img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 20px;
  transition: transform 0.3s;
}

.gallery-images img:hover {
  transform: scale(1.05);
}

/* Görsel galerisi alanı */
.gallery-images {
  display: flex;
  justify-content: space-between; /* Görseller arası boşluk */
  align-items: center;
  flex-wrap: wrap; /* Görselleri birden fazla satıra yerleştir */
  gap: 1rem; /* Görseller arasındaki boşluk */
  width: 100%;
}

/* Mobil düzen */
@media (max-width: 768px) {
  .gallery-images {
    gap: 1rem; /* Daha küçük boşluk */
  }

  .gallery-images img {
    max-width: calc(50% - 0.5rem); /* İki sütun düzeni için genişlik */
  }

  .gallery-title {
    font-size: 1.5rem;
  }

  .gallery-container {
    padding: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1439px) {
  .gallery-images {
    gap: 0.5rem; /* Boşlukları daha kontrollü hale getir */
  }

  .gallery-images img {
    max-width: calc(25% - 1rem); /* Üç sütun düzeni */
  }
}

/* 1440px ve üzeri ekranlar için gap'i azalt */
@media (min-width: 1440px) {
  .gallery-images {
    gap: 0rem; /* Boşluğu daha uygun hale getir */
  }

  .gallery-images img {
    max-width: calc(25% - 1rem); /* 5 sütun düzeni için genişlik */
  }
}
