.service-list {
  width: 50%;

  margin: 0 auto; /* Ortalamak için */
}

.service-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212121;
  text-align: left;
}

.line {
  width: 80px;
  height: 4px;
  background-color: #ff6600;
}

.service-items {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  max-width: 650px;
}

.service-items li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 45px;
  position: relative;
  font-size: 1rem;

  color: #212121;
}

.service-items li::before {
  content: "➜";
  color: #212121;
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 769px) and (max-width: 1366px) {
  .service-list {
    width: 90%; /* Daha dar ekranlar için genişliği azalt */
    padding: 10px; /* İç boşluğu küçült */
  }
}

/* Responsive tasarım için */
@media (max-width: 768px) {
  .service-list {
    width: 90%; /* Daha dar ekranlar için genişliği azalt */
    padding: 10px; /* İç boşluğu küçült */
  }

  .service-title {
    font-size: 0.875rem; /* Başlık boyutunu küçült */
    text-align: left; /* Mobilde başlığı ortala */
  }

  .service-items {
    text-align: left; /* Listeyi sola hizala */
  }

  .service-items li {
    font-size: 0.9rem; /* Yazı boyutunu küçült */
    padding-left: 40px; /* Bullet ile metin arasındaki boşluğu azalt */
  }

  .service-items li::before {
    width: 25px; /* Bullet boyutunu küçült */
    height: 25px;
    font-size: 0.9rem;
  }
}

/* Daha küçük ekranlar (telefonlar) için */
@media (max-width: 480px) {
  .service-list {
    width: 100%; /* Tam genişlik kullan */
    padding: 5px; /* Daha az iç boşluk */
  }

  .service-title {
    font-size: 1rem; /* Daha küçük başlık */
  }

  .service-items li {
    font-size: 0.85rem; /* Metin boyutunu iyice küçült */
    padding-left: 35px; /* Bullet ile metin arasındaki boşluğu daha da azalt */
  }

  .service-items li::before {
    width: 20px; /* Bullet boyutunu küçült */
    height: 20px;
    font-size: 0.8rem;
  }
}
