/* Galeri Konteyneri */
.p-gallery-container {
  width: 70%; /* Galeri genişliğini %80 olarak ayarladık */
  margin: 0 auto; /* Ortaya hizalama */
  padding: 50px;
  text-align: left;
}

/* Başlık */
.p-gallery-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}

.p-gallery-title span {
  color: #f57c00; /* Başlık alt çizgi rengi */
  border-bottom: 3px solid #f57c00;
  padding-bottom: 3px;
}
/* Galeri Ana Konteyner */
.p-gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Her sırada 4 sütun */
  gap: 10px; /* Görseller arasındaki boşluğu daraltmak için 10px */
  justify-items: center;
  align-items: center;
  margin-top: 20px; /* Başlık ve görseller arasında mesafe */
}

/* Üst ve Alt Sıradaki Resimler İçin Ortak Stil */
.p-gallery-item img {
  width: 300px; /* Tüm görsellerin genişliği */
  height: 250px; /* Tüm görsellerin yüksekliği */
  object-fit: cover; /* Görsellerin düzgün kesilmesi */
  border-radius: 20px;
}

/* Hover Efekti (İsteğe Bağlı) */
.p-gallery-item img:hover {
  transform: scale(1.05); /* Hover sırasında görsel büyür */
  transition: transform 0.3s ease-in-out;
}

/* Mobil Düzenlemeler */
@media screen and (max-width: 768px) {
  .p-gallery-container {
    width: 100%; /* Mobilde genişlik tam ekran olacak */
    padding: 20px;
  }

  .p-gallery-title {
    font-size: 1.5rem;
  }

  .p-gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Her sıradaki 2 sütun */
    gap: 10px; /* Görseller arasındaki boşluk */
    justify-items: center;
  }

  /* Mobilde tüm resimler aynı boyutta olacak */
  .p-gallery-item img {
    height: 150px; /* Görsellerin yüksekliği */
  }
}
