/* Genel Konteyner */
.p-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
}

/* İçerik Konteyneri */
.p-content-container {
  width: 90%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  font-size: 1rem;
  text-align: left;
}

/* Sol İçerik */
.p-left-content {
  width: 50%;
  max-width: 570px;
  flex-grow: 1;
}

.p-left-content p {
  margin-bottom: 1rem;
  line-height: 2rem;
  color: #333;
}

/* Sağ İçerik */
.p-right-content {
  width: 40%;
  max-width: 570px;
  flex-grow: 1;
}

.p-right-content ul {
  padding: 0;
  list-style: none;
}

.p-right-content li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 45px; /* Ensure text starts after the bullet */
  position: relative;
  font-size: 1rem;

  color: #212121;
}

.p-right-content li::before {
  content: "➜";
  color: #212121;
  background-color: #d9d9d9; /* Bullet color */
  border-radius: 50%; /* Make it circular */
  width: 30px; /* Fixed width for the bullet */
  height: 30px; /* Fixed height for the bullet */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Bullet size */
  position: absolute;
  left: 0; /* Position bullet at the start of the list item */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the bullet */
}

/* Başlıklar */
.p-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.line {
  width: 80px;
  height: 4px;
  background-color: #ff6600;
  margin-bottom: 1rem;
}
/* Responsive Tasarım */
@media (max-width: 768px) {
  .p-content-container {
    flex-direction: column;
    align-items: center;
  }

  .p-left-content,
  .p-right-content {
    width: 100%;
    max-width: none;
  }

  .p-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}
