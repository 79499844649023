/* Genel Konteyner */
.contact-container {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  padding: 40px;
}

/* Ana Kontener (section) */
.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

/* İçerik Konteyneri */
.contact-content {
  display: flex;
  gap: 20px;
  width: 100%;
}

/* İletişim Detayları Konteyneri */
.contact-details {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
}

/* İletişim Detayları */
.contact-detail {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
}

.contact-detail .icon {
  width: 40px;
  height: 40px;
  background-color: #fff5f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f68b1f;
  font-size: 18px;
  margin-right: 15px;
  flex-shrink: 0; /* İkon boyutunun sabit kalmasını sağlar */
}

.contact-detail h4 {
  font-size: 1rem;
  margin: 0;
  color: #212121;
  font-weight: 600;
}

.contact-detail p {
  font-size: 0.875rem;
  color: #212121;
  margin: 2px 0 0;
  font-weight: 400;
}

/* Form Konteyneri */
.contact-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  flex: 2;
  width: 590px;
  height: 520px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.message-content {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 1rem;
  font-weight: 600;
}

.contact-form h2 {
  margin-bottom: 10px;
}

.contact-form p {
  font-size: 0.813rem;
  color: #212121;
  font-weight: 400;
}

.form-item {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form-item-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
}

.form-row label {
  font-size: 0.875rem;
  font-weight: 600;
}

.contact-form .form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 14px;
}

.contact-form textarea {
  resize: vertical;
  height: 80px;
}

.contact-form button {
  background-color: #ff9800;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: 20px 0;
  font-size: 1rem;
  font-weight: 600;
}

.contact-form button:hover {
  background-color: #e68900;
}

/* Responsive Düzenlemeler */
@media screen and (max-width: 768px) {
  /* Kontener için düzenleme */
  .contact-container {
    padding: 20px;
  }

  /* İletişim bölümünü alt alta yerleştir */
  .contact-section {
    flex-direction: column; /* Dikey hizalama */
    gap: 30px; /* Aralarındaki boşluk */
  }

  /* Form ve detayları alt alta hizala */
  .contact-content {
    flex-direction: column;
    gap: 20px; /* Aralarındaki boşluk */
  }

  /* İletişim detayları */
  .contact-details {
    width: 100%;
  }

  /* Form için düzenlemeler */
  .contact-form {
    width: 100%; /* Mobilde form genişliği %100 */
    height: auto; /* Yüksekliği otomatik ayarlıyoruz */
  }

  .form-row {
    flex-direction: column;
  }
  /* Form öğeleri için düzenlemeler */
  .form-item {
    width: 100%; /* Mobilde form elemanlarının tamamı genişleyecek */
  }

  /* Buton boyutunu optimize et */
  .contact-form button {
    width: 100%; /* Butonu tam genişlikte yap */
  }
}
