/* Ana banner alanı */
.info-banner {
  background-color: #e67e22; /* Turuncu arka plan */
  color: #fff; /* Beyaz yazı */
  text-align: center;
  padding: 20px 0; /* Dikey boşluk */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-bottom: 2rem;
}

/* İçerik düzeni */
.info-banner-content {
  max-width: 600px; /* İçeriğin genişlik sınırı */
  width: 100%;
}

/* Yazı stili */
.info-banner-content p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1.5;
}

/* Buton stili */
.info-banner-button {
  background-color: transparent; /* Şeffaf arka plan */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px;
  border: 1px solid #fff; /* Beyaz çerçeve */
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover efekti */
.info-banner-button:hover {
  background-color: #fff;
  color: #e67e22;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .info-banner {
    padding: 20px 20px; /* Dikey boşluk */
  }
  .info-banner-content p {
    font-size: 18px;
  }
}
