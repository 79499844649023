/* Genel container ve içerik stil */
.asil-nun-x-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  flex-direction: row;
}

.asil-nun-x-content {
  display: flex;
  width:83%;
  justify-content: space-between;
}

/* Sol kısımdaki metin bölümü */
.asil-nun-x-text {
  flex: 1;
  max-width: 600px;
  color: #333;
  text-align: left;
}

/* Başlık ve paragraf stilleri */
.asil-nun-x-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 3px solid #e67e22;
  display: inline-block;
  padding-bottom: 5px;
}

.asil-nun-x-description,
.asil-nun-x-details,
.asil-nun-x-info {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.6;
}

/* Belge görselleri düzeni */
.asil-nun-x-documents {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.asil-nun-x-documents img {
  width: 150px;
  height: 90px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* İletişim metni */
.asil-nun-x-contact {
  margin-top: 20px;
  font-weight: bold;

  font-size: 16px;
  cursor: pointer;
}

.asil-nun-x-contact span {
  text-decoration: underline;
}

/* Sağdaki ana görsel */
.asil-nun-x-image {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: auto;
  position: relative;
}

/* Ana görsel ve arka plan için özel stil */
.asil-nun-x-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

/* Mobil ekranlar için responsive düzenlemeler */
@media screen and (max-width: 768px) {
  .asil-nun-x-container {
    flex-direction: column; /* Dikey hizalama */
    padding: 20px; /* İçerik alanına daha fazla boşluk bırak */
  }

  .asil-nun-x-content {
    flex-direction: column; /* İçeriği dikeyde hizala */
    align-items: center; /* İçeriği ortala */
  }

  .asil-nun-x-text {
    text-align: left; /* Metni ortala */
    max-width: 100%; /* Metin kutusunu ekran boyutuna göre genişlet */
    margin-bottom: 20px; /* Alt boşluk ekle */
  }

  .asil-nun-x-title {
    font-size: 24px; /* Başlık boyutunu küçült */
  }

  .asil-nun-x-description,
  .asil-nun-x-details,
  .asil-nun-x-info {
    font-size: 14px; /* Yazı boyutunu küçült */
  }

  .asil-nun-x-documents {
    flex-direction: row; /* Belge görsellerini alt alta diz */
    gap: 10px; /* Görseller arasına boşluk ekle */
    align-items: CENTER; /* Görselleri ortala */
  }

  .asil-nun-x-documents img {
    width: 80px;
    height: 60px;
  }
  .asil-nun-x-image {
    width: 100%; /* Görseli ekran boyutuna göre uyumlu hale getir */
    max-width: 300px; /* Görselin maksimum genişliğini ayarla */
    margin-top: 20px; /* Üstten boşluk ekle */
  }

  .asil-nun-x-image img {
    max-width: 100%; /* Görselin genişliğini ekran boyutuna göre sınırlama */
  }

  .asil-nun-x-contact {
    font-size: 14px; /* Metin boyutunu küçült */
    margin-top: 10px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .asil-nun-x-container {
    flex-direction: column; /* Dikey hizalama */
    padding: 20px; /* İçerik alanına daha fazla boşluk bırak */
  }

  .asil-nun-x-content {
    flex-direction: column; /* İçeriği dikeyde hizala */
    align-items: center; /* İçeriği ortala */
  }

  .asil-nun-x-text {
    text-align: left; /* Metni ortala */
    max-width: 100%; /* Metin kutusunu ekran boyutuna göre genişlet */
    margin-bottom: 20px; /* Alt boşluk ekle */
  }
  /* Sağdaki ana görsel */
  .asil-nun-x-image {
    width: 300px;
    display: none;
  }

  /* Ana görsel ve arka plan için özel stil */
  .asil-nun-x-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    position: relative;
    z-index: 1;
  }
}
